<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
    </van-sticky>
    <van-search
      v-model="page.filterData.name"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />

    <van-cell>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 0 ? '#9d1d22' : ''"
          @click="changeSorterType(0)"
          >默认</van-tag
        >
      </van-col>

      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 1 ? '#9d1d22' : ''"
          @click="changeSorterType(1)"
          >最新</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 3 ? '#9d1d22' : ''"
          @click="changeSorterType(3)"
          >最热</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 2 ? '#9d1d22' : ''"
          @click="changeSorterType(2)"
          >最早</van-tag
        >
      </van-col>
    </van-cell>

    <van-cell>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.filterData.folderType == 0 ? '#9d1d22' : ''"
          @click="changeFolderType(0)"
          >全部</van-tag
        >
      </van-col>
      <van-col span="5">
        <van-tag
          round
          size="large"
          :color="page.filterData.folderType == 1 ? '#9d1d22' : ''"
          @click="changeFolderType(1)"
          >仅文件</van-tag
        >
      </van-col>

      <van-col span="8">
        <van-tag
          round
          size="large"
          :color="page.filterData.folderType == 2 ? '#9d1d22' : ''"
          @click="changeFolderType(2)"
          >仅文件夹</van-tag
        >
      </van-col>
    </van-cell>
    <van-cell v-if="pathlist.length>0">
      <ul class="breadcrumb">
      <span @click="toFolder('00000000-0000-0000-0000-000000000000')">全部</span>
      <span v-for="item in pathlist" :key="item.id.toString()" @click="toFolder(item.id.toString())" >{{item.name}}</span>
    </ul>
    </van-cell>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-cell-group v-if="false">
          <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
          <van-cell
            @click="toDetail(item)"
            v-for="item in articles"
            :key="item.id.toString()"
          >
            <van-col span="12">
              <img :src="FileImageUrl(item)" style="max-height: 100px" />
            </van-col>
            <van-col span="12" align="bottom">
              <div
                style="display: table-cell; vertical-align: middle"
                class="zntitle van-multi-ellipsis--l3"
              >
                <h4 style="display: inline-block">{{ item.name }}</h4>
              </div>
              <div style="float: left">
                <van-tag type="primary" v-if="!item.folder">文件</van-tag>
                <van-tag type="primary" v-if="item.folder">文件夹</van-tag>
              </div>

              <div style="float: right" v-if="!item.folder">
                {{ FileSize(item) }}
              </div>
              <div style="float: right; margin-right: 15px">
                {{ item.creationTime }}
              </div>
            </van-col>
          </van-cell>
        </van-cell-group>

        <div class="van-clearfix">
          <div
            class="layout_wrapper"
            style="
              float: left;
              width: 50%;
              text-align: center;
              background-color: #f8f8f8;
              overflow: hidden;
            "
            @click="toDetail(item)"
            v-for="item in articles"
            :key="item.id.toString()"
          >
            <div class="product-layout__item">
              <van-image
                lazy-load
                class="w100"
                fit="contain"
                style="max-height: 200px"
                :src="FileImageUrl(item)"
              />

              <div style="height: 42px">
                <h3 style="display: inline-block; height: 21px">
                  {{ item.name }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getCloudDiskList,getPath } from "@/api/clouddisk";

//const key = "zn-history";
export default {
  name: "clouddisk",
  data() {
    return {
      scroll: 0,
      refreshing: false,
      type: 100,
      title: "中酿资料库",
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      pathlist:[],
      page: {
        sorterType: 0,
        current: 1,
        pageSize: 10,
        filterData: {
          name: "",
          folderID: "00000000-0000-0000-0000-000000000000",
          folderType: 0,
        },
      },
    };
  },
  components: {},
  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
   
  },
  watch: {
    $route(to, from) {
      if (to.path!='/clouddisk/detail' &&  from.path != "/clouddisk/detail") {
       
        //监听路由是否变化
        if (to.query != from.query) {
          this.initData();
          this.onSearch();
        }
      }
    },
  },
  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
   async initData() {
      this.page.sorterType = 0;
      this.page.filterData.folderType = 0;
      let folderID = this.$route.query.folderID;
      if (folderID === undefined) {
        folderID = "00000000-0000-0000-0000-000000000000";
      }

      this.page.filterData.folderID = folderID;
      this.page.filterData.name = "";

      //获取目录
      if(folderID=="00000000-0000-0000-0000-000000000000"){
        this.pathlist=[];
      }
      else{
         var pathresult=await getPath(folderID);

         this.pathlist=pathresult.data.data.reverse();
      }

      

    },
    onSearch() {
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    changeSorterType(typeid) {
      if (this.page.sorterType != typeid) {
        this.page.sorterType = typeid;
        this.onSearch();
      }
    },
    changeFolderType(typeid) {
      if (this.page.filterData.folderType != typeid) {
        this.page.filterData.folderType = typeid;
        this.onSearch();
      }
    },
    toDetail(item) {
      if (item.folder) {
        //this.page.filterData.folderID = item.id;
        //this.onSearch();
        //this.$router.push('/login')
        //http://192.168.10.231:8080/#/clouddisk?id=
        this.$router.push({ path: "/clouddisk", query: { folderID: item.id } });
      } else {
        let wrapperScrollTop = this.$refs.wrapper.scrollTop;
        //存储 scrollTop 的值
        this.scroll = wrapperScrollTop;
        this.$router.push({
          path: "/clouddisk/detail",
          query: { para: item.id },
        });
      }
      //this.$router.push({ path: "/article/detail", query: { para: item.id, type: this.page.filterData.typeId } });
    },
    toFolder(id){
      this.$router.push({ path: "/clouddisk", query: { folderID: id } });
    },
    

    async onLoad() {
      this.upLoading = true;
      let aresult = await getCloudDiskList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
        this.finished = false;
      } else {
        this.finished = true;
      }
    },
    FileSize(record) {
      var fileSize = Math.round(record.fileSize / 1024);
      var suffix = "KB";
      if (fileSize > 1000) {
        fileSize = Math.round(fileSize / 1000);
        suffix = "MB";
      }

      return fileSize + suffix;
    },
    FileImageUrl(item) {
      if (item.folder) {
        return "/image/filetype/FolderType.png";
      } else {
        if (item.privewFileUrl != undefined && item.privewFileUrl != "") {
          return item.privewFileUrl;
        } else {
          if (item.fileType.indexOf("image") >= 0) {
            return "/image/filetype/ImgType.png";
          } else if (item.fileType.indexOf("audio") >= 0) {
            return "/image/filetype/MusicType.png";
          } else if (item.fileType.indexOf("video") >= 0) {
            return "/image/filetype/VideoType.png";
          } else {
            return "/image/filetype/GeneralType.png";
          }
        }

        //video/mp4
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  padding: 10px 0;
  list-style-type: none;
}

.breadcrumb span {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px; 
}
.zntitle {
  height: 50px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.product-layout__item {
  position: relative;
  display: block;
  box-sizing: border-box;
  min-height: 50px;
  overflow: hidden;
  color: #333;
  background-color: #fff;
  margin: 2px;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
